<template>
  <BasePage :loading="loading">
    <Container>
      <div v-if="result && !loading" class="">
        <h2 class="text-h3 primary--text">{{ $t('testResult') }}</h2>
        <div>
          <!-- https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf -->
          <!-- http://localhost:8080/#/result?admin_result=i-j-n-t&course_id=34 -->
          <div style="borderRadius: 5px;border: 1px solid #eee" class=" my-5">
            <!-- v-if="result.attachment || result.result_option_id.attachment" -->
            <!-- <PdfViewer
              initialDoc="https://pdftron.s3.amazonaws.com/downloads/pl/demo-annotated.pdf
              "
            /> -->
            <PdfViewer
              v-if="result.attachment || result.result_option_id.attachment"
              :initialDoc="
                domain +
                  (enrollment_id
                    ? result.result_option_id.attachment.data.asset_url
                    : result.attachment.data.asset_url)
              "
            />
          </div>
        </div>
        <div
          class="d-flex justify-center my-15"
          v-if="
            result.video_link ||
              (result.result_option_id && result.result_option_id.video_link)
          "
        >
          <iframe
            :src="
              enrollment_id
                ? result.result_option_id.video_link
                : result.video_link
            "
            width="100%"
            height="600px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <v-btn
          v-if="
            result.plane ||
              (result.result_option_id && result.result_option_id.plane)
          "
          target="_blank"
          :href="
            enrollment_id
              ? result.result_option_id.plane.data.full_url
              : result.plane.data.full_url
          "
          color="primary"
        >
          <v-icon>mdi-attachment</v-icon>
          {{ $t('developmentPlan') }}
        </v-btn>
      </div>
    </Container>
  </BasePage>
</template>

<script>
import ApiService from '@/services/api.service';
export default {
  props: ['enrollment_id'],
  components: {
    PdfViewer: () => import('../../components/PdfViewer'),
  },
  data() {
    return {
      loading: false,
      result: null,
      domain: process.env.VUE_APP_DOMAIN,
      admin_result: this.$route.query.admin_result,
      course_id: this.$route.query.course_id,
    };
  },
  methods: {
    async getResult() {
      try {
        this.loading = true;
        if (this.enrollment_id) {
          const { data } = await ApiService.get(
            `items/enrollment/${this.enrollment_id}?fields=*,result_option_id.plane.data.full_url,result_option_id.video_link,result_option_id.attachment.data.full_url,result_option_id.id`
          );
          this.result = data.data;
        } else if (this.admin_result) {
          const { data } = await ApiService.get(
            `items/result_options?fields=id,plane.data.full_url,video_link,attachment.data.full_url&filter[result]=${this.admin_result}&filter[course_id]=${this.course_id}`
          );

          this.result = data.data[0];
        }

        // this.showResult = true;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>

<style></style>
