var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePage',{attrs:{"loading":_vm.loading}},[_c('Container',[(_vm.result && !_vm.loading)?_c('div',{},[_c('h2',{staticClass:"text-h3 primary--text"},[_vm._v(_vm._s(_vm.$t('testResult')))]),_c('div',[_c('div',{staticClass:" my-5",staticStyle:{"borderRadius":"5px","border":"1px solid #eee"}},[(_vm.result.attachment || _vm.result.result_option_id.attachment)?_c('PdfViewer',{attrs:{"initialDoc":_vm.domain +
                (_vm.enrollment_id
                  ? _vm.result.result_option_id.attachment.data.asset_url
                  : _vm.result.attachment.data.asset_url)}}):_vm._e()],1)]),(
          _vm.result.video_link ||
            (_vm.result.result_option_id && _vm.result.result_option_id.video_link)
        )?_c('div',{staticClass:"d-flex justify-center my-15"},[_c('iframe',{attrs:{"src":_vm.enrollment_id
              ? _vm.result.result_option_id.video_link
              : _vm.result.video_link,"width":"100%","height":"600px","frameborder":"0","allow":"autoplay; fullscreen; picture-in-picture","allowfullscreen":""}})]):_vm._e(),(
          _vm.result.plane ||
            (_vm.result.result_option_id && _vm.result.result_option_id.plane)
        )?_c('v-btn',{attrs:{"target":"_blank","href":_vm.enrollment_id
            ? _vm.result.result_option_id.plane.data.full_url
            : _vm.result.plane.data.full_url,"color":"primary"}},[_c('v-icon',[_vm._v("mdi-attachment")]),_vm._v(" "+_vm._s(_vm.$t('developmentPlan'))+" ")],1):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }